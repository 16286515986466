<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu PO chia hàng'">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div
                        class="py-8 px-4 py-lg-15 px-lg-4 pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <b-row class="mb-5">
                          <!-- INFO INPUT CONTROLS -->
                          <b-col md="6">
                            <POCommonInfo
                              :mainModel="mainModel"
                              :parentPO="true"
                              v-if="renderComponent"
                            />
                          </b-col>
                          <!-- INFO VAT INPUT CONTROLS -->
                          <b-col md="3">
                            <b-form-group :disabled="true">
                              <label>Doanh nghiệp:</label>
                              <b-form-select
                                class="select-style"
                                v-model="mainModel.companyId"
                                :options="listCompany"
                                size="sm"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-group :disabled="true">
                              <label>Hình thức thanh toán:</label>
                              <b-form-select
                                class="select-style"
                                v-model="mainModel.importType"
                                :options="listImportType"
                                size="sm"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-group :disabled="true">
                              <label style="margin-bottom: 0.75rem"
                                >Chiết khấu:</label
                              >
                              <b-row>
                                <b-col class="pr-0" cols="5">
                                  <b-form-select
                                    class="select-style"
                                    v-model="mainModel.discountType"
                                    :options="listAmountType"
                                    size="sm"
                                    value-field="id"
                                    text-field="name"
                                  >
                                  </b-form-select>
                                </b-col>
                                <b-col class="pl-0" cols="7">
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    v-model="mainModel.discountAmount"
                                    v-mask="formater.currency"
                                    placeholder="Chiết khấu"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>

                            <POVat
                              :editable="false"
                              :mainModel="mainModel"
                              v-if="renderComponent"
                            />
                          </b-col>
                          <!-- PAYMENT INPUT CONTROLS -->
                          <b-col>
                            <POPayment
                              :editable="false"
                              :mainModel="mainModel"
                              v-if="renderComponent"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="8">
                            <span class="font-weight-bolder lable-size"
                              >Danh sách sản phẩm:</span
                            >
                          </b-col>
                          <b-col md="4">
                            <b-checkbox
                              :disabled="true"
                              v-model="mainModel.isVAT"
                              class="d-flex justify-content-end lable-size"
                              >Đơn giá đã bao gồm VAT
                            </b-checkbox>
                          </b-col>
                        </b-row>
                        <b-table
                          :fields="fieldProduct"
                          :items="mainModel.details"
                          v-if="renderComponent"
                          hover
                          bordered
                          class="mt-4"
                        >
                          <template v-slot:cell(productName)="row">
                            <span
                              v-text="row.item.barCode"
                              style="font-size: 11px"
                              v-show="row.item.barCode"
                            ></span>
                            <br />
                            <span
                              v-text="row.item.productCode"
                              style="font-size: 12px"
                              v-show="row.item.productCode"
                            ></span>
                            <br />
                            <span
                              v-text="row.item.productName"
                              style="white-space: normal; color: #3699ff"
                            ></span>
                          </template>
                        </b-table>
                        <hr
                          class="hr-text"
                          data-content="Danh sách chia hàng"
                          style="font-weight: 600"
                        />
                        <b-table
                          :fields="fieldStores"
                          :items="inventories"
                          v-if="renderComponent"
                          hover
                          bordered
                          class="mt-4 col-md-6"
                        >
                          <template v-slot:cell(detail)="row">
                            <div
                              class="productCode"
                              style="cursor: pointer; color: blue"
                              @click="showModalShareGoods(row.item)"
                            >
                              <i
                                class="fa fa-shopping-cart"
                                v-b-tooltip.hover
                                title="Chia hàng"
                              ></i>
                            </div>
                          </template>
                        </b-table>

                        <div class="col-md-12" style="padding-left: 0px">
                          <div class="mb-5 mt-5">
                            <b-button
                              v-bind:style="btnCreate"
                              size="sm"
                              variant="primary"
                              class="mr-4"
                              @click="onSubmit"
                            >
                              Duyệt
                            </b-button>
                            <b-button
                              v-bind:style="btnCancel"
                              size="sm"
                              variant="warning"
                              class="mr-4"
                              @click="showModalCancel"
                            >
                              Trả phiếu
                            </b-button>
                          </div>
                          <CancelPO
                            ref="po-cancel"
                            :purchaseOrderCode="mainModel.code"
                            :purchaseOrderId="mainModel.id"
                            :mode="
                              mainModel.status === PURCHASE_ORDER_STATUS.READY
                                ? PURCHASE_ORDER_APPROVE_MODE.PURCHASE
                                : PURCHASE_ORDER_APPROVE_MODE.ACCOUNTANT
                            "
                            v-on:onSuccess="onBack"
                          />
                          <ShareGoods
                            ref="share-goods-modal"
                            :editable="false"
                          />
                        </div>
                      </div>

                      <div
                        class="py-4 mb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <div class="upload-file col-12">
                          <UploadFile
                            v-if="mainModel.id"
                            :id="mainModel.id"
                            :entity="'purchase-orders'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { sumBy } from 'lodash';
import {
  currencyMask,
  makeToastFaile,
  unMaskPrice,
  convertPrice,
  makeToastSuccess,
} from '../../../utils/common';
import { PURCHASE_ORDER_TYPE, TIME_TRIGGER } from '../../../utils/constants';
import {
  PURCHASE_ORDER_APPROVE_MODE,
  PURCHASE_ORDER_STATUS,
} from '../../../utils/enum';

import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import POPayment from '../../components/purchase-orders/POPayment';
import POVat from '../../components/purchase-orders/POVat';
import POCommonInfo from '../../components/purchase-orders/POCommonInfo';
import ShareGoods from '../../components/purchase-orders/ShareGoods';
import CancelPO from '../../components/stock-slips/CancelPO';
import UploadFile from '@/view/modules/upload/Upload';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      isNew: true,
      formater: {
        currency: currencyMask,
      },
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      fieldProduct: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'prevUnitPrice',
          label: 'Giá nhấp nhất',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'salePowerWeek',
          label: 'Sức bán tuần',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'salePowerMonth',
          label: 'Sức bán tháng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '6%' },
          tdClass: 'text-right',
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá (VAT)',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value, key, item) => {
            if (!item) {
              return 0;
            }
            const totalAmount =
              unMaskPrice(item.quantity) * unMaskPrice(item.unitPrice);
            return convertPrice(totalAmount);
          },
        },
        {
          key: 'salePrice',
          label: 'Đơn giá bán',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
      ],
      renderComponent: true,
      mainModel: {
        id: null,
        code: '',
        status: 1,
        providerId: null,
        providerName: '',
        description: '',
        storeId: null,
        discountAmount: 0,
        discountType: 1,
        vatAmount: 0,
        vatType: 2,
        cashAmount: 0,
        cashAccountCode: '',
        transferAmount: 0,
        transferAccountCode: '',
        payDate: null,
        vatCode: '',
        vatDate: '',
        createdAt: '',
        createdBy: '',
        importType: 1,
        importReason: '',
        inChargeEmployeeName: '',
        companyId: null,
        isVAT: true,
        totalAmount: 0,
        details: [],
      },
      PURCHASE_ORDER_STATUS: PURCHASE_ORDER_STATUS,
      listImportType: PURCHASE_ORDER_TYPE,
      hashName: null,
      listCompany: [],
      fieldStores: [
        {
          key: 'name',
          label: 'Kho',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'quantity',
          label: 'Tổng SL',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
          formatter: (value, key, item) => {
            if (!item) {
              return 0;
            }
            const totalQuantity = sumBy(item.products, 'quantity');
            return totalQuantity ? convertPrice(totalQuantity) : 0;
          },
        },
        {
          key: 'totalAmount',
          label: 'Tổng tiền',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
          formatter: (value, key, item) => {
            if (!item) {
              return 0;
            }
            const totalAmount = this.calTotalAmountProducts(item.products);
            return convertPrice(totalAmount);
          },
        },
        {
          key: 'detail',
          label: 'Chia hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-center',
        },
      ],
      inventories: [],
      PURCHASE_ORDER_APPROVE_MODE: PURCHASE_ORDER_APPROVE_MODE,
    };
  },
  components: {
    KTCodePreview,
    POPayment,
    POVat,
    ShareGoods,
    POCommonInfo,
    CancelPO,
    UploadFile,
  },
  created() {
    this.fetchCompany();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: '/purchase-orders' },
      { title: 'Thông tin phiếu PO chia hàng' },
    ]);
    this.mainModel.id = this.$route.query.id;
    this.getInfoById();
    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  computed: {},
  methods: {
    convertPrice,
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onBack() {
      this.$router.push({
        name: 'list-purchase-orders',
      });
    },
    getInfoById() {
      ApiService.get(`purchase-order/purchase-order-share/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel.status = data.data.status
            ? parseInt(data.data.status)
            : 1;
          this.mainModel.code = data.data.code ? data.data.code : '';
          this.mainModel.description = data.data.description
            ? data.data.description
            : '';
          this.mainModel.importReason = data.data.importReason
            ? data.data.importReason
            : '';
          this.mainModel.storeName = data.data.storeName
            ? data.data.storeName
            : '';
          this.mainModel.storeId = data.data.storeId;
          this.mainModel.providerName = data.data.providerName
            ? data.data.providerName
            : '';
          this.mainModel.providerId = data.data.providerId;
          this.mainModel.createdBy = data.data.createdByName
            ? data.data.createdByName
            : '';
          this.mainModel.inChargeEmployeeName = data.data.inChargeEmployeeName
            ? data.data.inChargeEmployeeName
            : '';
          this.selectedEmployee = data.data.inChargeEmployeeId;
          this.mainModel.companyId = data.data.companyId;
          this.mainModel.createdAt = data.data.createdAt
            ? moment(data.data.createdAt).format('HH:mm:ss DD/MM/YYYY')
            : '';
          this.mainModel.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.mainModel.vatAmount = data.data.vatAmount
            ? data.data.vatAmount
            : 0;
          this.mainModel.isVAT = data.data.isVAT;
          this.mainModel.discountType = data.data.discountType
            ? data.data.discountType
            : 1;
          this.mainModel.vatType = data.data.vatType ? data.data.vatType : 2;
          this.mainModel.cashAmount = data.data.cashAmount
            ? data.data.cashAmount
            : 0;
          this.mainModel.cashAccountCode = data.data.cashAccountCode
            ? data.data.cashAccountCode
            : '';
          this.mainModel.transferAmount = data.data.transferAmount
            ? data.data.transferAmount
            : 0;
          this.mainModel.transferAccountCode = data.data.transferAccountCode
            ? data.data.transferAccountCode
            : '';
          this.mainModel.payDate = data.data.payDate
            ? moment(data.data.payDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.vatDate = data.data.vatDate
            ? moment(data.data.vatDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.vatCode = data.data.vatCode ? data.data.vatCode : '';
          this.mainModel.importType = data.data.type ? data.data.type : 1;
          this.mainModel.details = data.data.details.map((item) => {
            const totalAmount =
              item.quantity * item.unitPrice - item.discountAmount;
            return {
              ...item,
              totalAmount: totalAmount,
            };
          });
          this.inventories = data.data.inventories.map((inventory) => {
            return {
              ...inventory,
            };
          });
          this.mainModel.totalAmount = data.data.totalAmount;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    fetchCompany: async function () {
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Chọn doanh nghiệp',
        });
      });
    },
    showModalShareGoods(item) {
      this.$refs['share-goods-modal'].showModal(
        item.id,
        item.name,
        item.products
      );
    },
    calTotalAmountProducts(products) {
      let totalAmountOfProducts = 0;
      products.forEach(({ productId, quantity = 0 }) => {
        const productFound = this.mainModel.details.find(
          (product) => product.productId === productId
        );
        const unitPrice = productFound ? productFound.unitPrice : 0;
        totalAmountOfProducts += unMaskPrice(unitPrice) * unMaskPrice(quantity);
      });
      return totalAmountOfProducts;
    },
    onSubmit: async function () {
      if (!this.mainModel.providerId) {
        makeToastFaile('Vui lòng chọn nhà cung cấp!');
        return;
      }

      const data = {
        id: this.mainModel.id,
        mode:
          this.mainModel.status === PURCHASE_ORDER_STATUS.READY
            ? PURCHASE_ORDER_APPROVE_MODE.PURCHASE
            : PURCHASE_ORDER_APPROVE_MODE.ACCOUNTANT,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post('purchase-order/approve', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.isNew = true;
              makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  name: 'list-purchase-orders',
                });
              }, TIME_TRIGGER);
            } else {
              this.isNew = true;
              makeToastFaile(data.message);
            }
          })
          .catch(({ response }) => {
            this.isNew = true;
            makeToastFaile(response.data.message);
          });
      }
    },
    showModalCancel() {
      this.$refs['po-cancel'].showModal();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
